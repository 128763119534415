export const torres = [
 ""
];

export const conceptosOtrosRecargos = {
  "AySA No Residencial No Medido": [
    "ajuste art. 2 resol. sop 2024-09",
    "descuento por tarifa social",
    "descuento transitorio tarifa social",
    "cargo por titularidad no informada",
    "subsidio del estado nacional",
  ],
  "AYSA NO RESIDENCIAL MEDIDO": [
    "ajuste art. 2 resol. sop 2024-09",
    "descuento por tarifa social",
    "descuento transitorio tarifa social",
    "cargo por titularidad no informada",
    "subsidio del estado nacional",
  ],
  "AySA Residencial No Medido": [
    "ajuste art. 2 resol. sop 2024-09",
    "descuento por tarifa social",
    "descuento transitorio tarifa social",
    "cargo por titularidad no informada",
    "subsidio del estado nacional",
  ],
  "AYSA": [
    "ajuste art. 2 resol. sop 2024-09",
    "descuento por tarifa social",
    "descuento transitorio tarifa social",
    "cargo por titularidad no informada",
    "subsidio del estado nacional",
  ],
  "AySA Baldío No Medido": [
    "ajuste art. 2 resol. sop 2024-09",
    "descuento por tarifa social",
    "descuento transitorio tarifa social",
    "cargo por titularidad no informada",
    "subsidio del estado nacional",
  ],
  "EDEN T2 B1": [
    "ajuste de interes por mora",
    "gastos administrativos",
    "dec ley 7290/67 - fdo des elec. ley 15.310 art.80",
  ],
  "CALF T3GDBT 1 Baja Tensión": ["multa intimada"],
  "EPEC TARIFA 3-GDES. CO": ["redondeo"],
  "EPEC CONT.EN BAJA": ["redondeo"],
  "EDEMSA T2 Especial": ["redondeo", "recargos por mora 1758647\n37", "recargos por mora 1758647", "recargos por mora"],
  "EDEMSA T2 R BT": ["redondeo", "recargos por mora 1758647\n37", "recargos por mora 1758647", "recargos por mora"],

};

export const conceptosOtrosImpuestos = {
  "Edesur T1": ["ley 7290/67", "ley 7290/67 periodo anterior"],
  "Edesur T2": [
    "contribución provincial 0.001%",
    "contribución municipal 6.424%",
    "percep iva rg2408/08 3.000%",
    "imp. valor agregado 27.000%",
    "ley 7290/67 1.000%",
    "ley 7290/67",
    "ley 7290/67 periodo anterior",
  ],
  "Edesur T3": [
    "imp. valor agregado 27.000%",
    "contribución municipal 6.383%",
    "contribución municipal 6.424%",
    "contribución provincial 0.001%",
    "percep iva rg2408/08 3.000%",
    "imp. valor agregado 21.000%",
  ],
  "Edenor T3": [
    "decreto-ley 7.290/67 s/ley 15.479 pba",
    "contribución municipal",
    "contribución provincial",
    "impuesto al valor agregado",
    "iva percepcion - res. gral. 2408/08",
  ],
  "Edenor T2": [],
  "AySA No Residencial No Medido": [
    "financiamiento eras",
    "financiamiento apla",
    "perc. iibb caba",
    "perc. iva suj. no categ.",
  ],
  "AYSA NO RESIDENCIAL MEDIDO": [
    "financiamiento eras",
    "financiamiento apla",
    "perc. iibb caba",
    "perc. iva suj. no categ.",
  ],
  "AySA Residencial No Medido": [
    "financiamiento eras",
    "financiamiento apla",
    "perc. iibb caba",
    "perc. iva suj. no categ.",
  ],
  "AySA Baldío No Medido": [
    "financiamiento eras",
    "financiamiento apla",
    "perc. iibb caba",
    "perc. iva suj. no categ.",
  ],
  "AYSA": [
    "financiamiento eras",
    "financiamiento apla",
    "perc. iibb caba",
    "perc. iva suj. no categ.",
  ],
  "EDEN T2 B1": [],
  "CALF T3GDBT 1 Baja Tensión": [
    "art 6 contribucion nuevo can",
    "art 1,2,3 acuerdo calf/cammesa",
    "pico",
  ],
  "CALF T2MD Baja Tensión": [
    "art 6 contribucion nuevo can",
    "art 1,2,3 acuerdo calf/cammesa",
  ],
  "EDEMSA T2 Especial": ["imp.sellos-art240 cod fiscal-ley8523 nuevo contrato"],
};

export const mappingItems = {
  "AYSA": [
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Cargo Variable",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva base 21%",
      nombreForm: "IVA 21",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "factura minima",
      nombreForm: "Cargo Variable",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "subsidio del estado nacional",
      nombreForm: "Ajustes y otros conceptos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva base",
      nombreForm: "IVA 27",
      consump: false,
      cost: true,
    },
  ],
  "Edenor T1": [
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo T1",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "días comprendidos",
      nombreForm: "Energía consumida T1",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo variable hasta 800 kwh",
      nombreForm: "CV primero Kwh",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable hasta 800",
      nombreForm: "CV primero Kwh",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "CV excedente Kwh",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial",
      nombreForm: "Contribución Provincial 0,001 %",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "decreto-ley 7.290/67 s/ley 15.479 pba",
      nombreForm: "Decreto-Ley 7.290/67 s/Ley 15.479 PBA",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "impuesto al valor agregado",
      nombreForm: "Imp. Valor Agregado 27%",
      consump: false,
      cost: true,
    },
  ],
  "Edenor T2": [
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo T2",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Energía activa T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energia (kwh)",
      nombreForm: "Energía activa T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo por potencia contratada",
      nombreForm: "Potencia Contratada/Convenida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo por potencia adquirida",
      nombreForm: "Potencia Adquirida/Consumida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo por potencia excedida",
      nombreForm: "Potencia Excedida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada/Convenida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia Adquirida/Consumida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Potencia Excedida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "energía reactiva",
      nombreForm: "Energía Reactiva T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "consumo período",
      nombreForm: "Energía Reactiva T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "real",
      nombreForm: "Energía Reactiva T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "intereses por pago fuera de termino",
      nombreForm: "Intereses por pago fuera de término",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal",
      nombreForm: "Contribución Municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial",
      nombreForm: "Contribución Provincial",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "impuesto al valor agregado",
      nombreForm: "Imp. Valor Agregado",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno presultante",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "decreto-ley 7.290/67 s/ley 15.479 pba",
      nombreForm: "Decreto-Ley 7.290/67 s/Ley 15.479 PBA",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "27,0000 %",
      nombreForm: "Imp. Valor Agregado",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "1,0000 %",
      nombreForm: "Decreto-Ley 7.290/67 s/Ley 15.479 PBA",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "6,4240 %",
      nombreForm: "Contribución Municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal por alumbrado público",
      nombreForm: "Tasa municipal por alumbrado Público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva percepcion - res. gral. 2408/08",
      nombreForm: "IVA Percepcion - Res. Gral. 2408/08",
      consump: false,
      cost: true,
    },
  ],
  "Edenor T3": [
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo variable pico",
      nombreForm: "Energía en punta",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo por potencia contratada",
      nombreForm: "Potencia Contratada/Convenida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada/Convenida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo por potencia adquirida",
      nombreForm: "Potencia Adquirida/Consumida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia Adquirida/Consumida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo variable valle",
      nombreForm: "Energía en valle",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energía en valle",
      nombreForm: "Energía en valle",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo por potencia excedida",
      nombreForm: "Potencia Excedida T2",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Potencia Excedida T2",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tangente",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo variable resto",
      nombreForm: "Energía en resto",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energía en resto",
      nombreForm: "Energía en resto",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial",
      nombreForm: "Contribución Provincial",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal",
      nombreForm: "Contribución Municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal por alumbrado público",
      nombreForm: "Tasa municipal por alumbrado Público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía reactiva",
      nombreForm: "Energia Reactiva T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "impuesto al valor agregado",
      nombreForm: "Imp. Valor Agregado",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva percepcion - res. gral. 2408/08",
      nombreForm: "IVA Percepcion - Res. Gral. 2408/08",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "27,0000 %",
      nombreForm: "Imp. Valor Agregado",
      consump: false,
      cost: true,
    },
  ],
  "Edesur T1": [
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo T1",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percep iva rg2408/08",
      nombreForm: "Percep IVA RG2408/08",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "imp. valor agregado",
      nombreForm: "Imp. Valor Agregado 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial",
      nombreForm: "Contribución Provincial 0,001 %",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "bonif.multa res enre n° 192/24",
      nombreForm: "Bonif. Estimación",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "bonif. estimación",
      nombreForm: "Bonif. Estimación",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "total tasa municipal a.p.",
      nombreForm: "Total Tasa Municipal A.P",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Energía consumida T1",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "detalle de su liquidación",
      nombreForm: "Energía consumida T1",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cv primeros 800 kwh",
      nombreForm: "CV primero Kwh",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cv excedente a 800 kwh",
      nombreForm: "CV excedente Kwh",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "interes multa",
      nombreForm: "Interes Multa",
      consump: false,
      cost: true,
    },
  ],
  "Edesur T2": [
    {
      nombreFactura: "cargo fijo t2",
      nombreForm: "Cargo Fijo T2",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cap.sum.conv t2",
      nombreForm: "Capacidad de suministro convenida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cap.sum.adquirida",
      nombreForm: "Capacidad de suministro adquirida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Energía consumida T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "recargo energía reactiva t2",
      nombreForm: "Energía reactiva T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "imp. valor agregado 27.000%",
      nombreForm: "Imp. Valor Agregado 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energ.hrs.resto inyectada t2",
      nombreForm: "Energía Inyectada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "coseno fi medido",
      nombreForm: "Coseno fi",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "percep iva rg2408/08 3.000%",
      nombreForm: "Percep IVA RG2408/08",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percep iva rg2408/0 3.000%",
      nombreForm: "Percep IVA RG2408/08",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial 0.001%",
      nombreForm: "Contribución Provincial 0,001 %",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 6.424%",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 6.383%",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa alumbrado público t2",
      nombreForm: "Tasa municipal por alumbrado Público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "recargo exceso potencia t2",
      nombreForm: "Exceso Potencia T2",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "interés mora",
      nombreForm: "Intereses por pago fuera de término",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "boni def sem",
      nombreForm: "Ajuste_Bon_Def_Sem_43",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "bonificacion",
      nombreForm: "Ajuste_Bon_Def_Sem_43",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "bonificación",
      nombreForm: "Ajuste_Bon_Def_Sem_43",
      consump: false,
      cost: true,
    },
  ],
  "Edesur T3": [
    {
      nombreFactura: "cargo fijo t3",
      nombreForm: "Cargo Fijo T3",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cap.sum.conv. t3",
      nombreForm: "Capacidad de suministro convenida T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cap.sum.conv t3",
      nombreForm: "Capacidad de suministro convenida T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cap.sum.adquirida t3",
      nombreForm: "Capacidad de suministro adquirida T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "recargo energia reactiva t3",
      nombreForm: "Energia Reactiva T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "recargo exceso potencia t3",
      nombreForm: "Exceso Potencia T3",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "imp. valor agregado 27.000%",
      nombreForm: "Imp. Valor Agregado 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energ. hrs. restantes",
      nombreForm: "Energ. Hrs. Restantes",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ. hrs. restante",
      nombreForm: "Energ. Hrs. Restantes",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ. hrs. punta",
      nombreForm: "Energ. Hrs. Punta",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ.hrs.resto inyectada",
      nombreForm: "Energ. Hrs. Resto inyectada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ.hrs.valle noc.inyectada",
      nombreForm: "Energ. Hrs. Valle Noc. Inyectada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ.hrs.punta inyectada t3",
      nombreForm: "Energ. Hrs. Punta inyectada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energ. hrs. valle noc.",
      nombreForm: "Energ. Hrs. Valle Noc.",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "tangente",
      nombreForm: "Tangente fi",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "tangente fi medido",
      nombreForm: "Tangente fi",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Tangente fi",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "contribución provincial 0.001%",
      nombreForm: "Contribución Provincial 0,001 %",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 6.424%",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 6.383%",
      nombreForm: "Contribución Municipal 6,383%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "boni def sem",
      nombreForm: "Ajuste_Bon_Def_Sem_43",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "interés mora",
      nombreForm: "Intereses por pago fuera de término",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percep iva rg2408/08 3.000%",
      nombreForm: "Percep IVA RG2408/08",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "imp. valor agregado 21.000%",
      nombreForm: "Imp. Valor Agregado 21%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo por comercializacion",
      nombreForm: "Cargo por Comercialización",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo por administracion",
      nombreForm: "Cargo por Administración",
      consump: false,
      cost: true,
    },
  ],
  "EDET - T4B": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Exceso de potencia",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "aporte tis-resol. n°463/me",
      nombreForm: "Aporte TIS-Resol. N°463/ME",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "imp. nac. ley 25.413",
      nombreForm: "Imp. Nac. Ley 25.413",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "registros fuera de pico (05 a 18 hs) kwh",
      nombreForm: "Energía consumida Fuera de Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "registros en pico (18 a 23 hs) kwh",
      nombreForm: "Energía consumida en Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "registros nocturnos (23 a 05 hs) kwh",
      nombreForm: "Energía consumida Nocturno",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tic-art. 63 ley 6608",
      nombreForm: "TIC-Art. 63 Ley 6608 actualiz.",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tic-art. 63 ley 6608 actualiz",
      nombreForm: "TIC-Art. 63 Ley 6608 actualiz.",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva resp. inscr. 27%",
      nombreForm: "IVA Resp. Inscr. 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "bonif. fact. potencia",
      nombreForm: "Energía Reactiva",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept790/16:inv.oblig.tpte",
      nombreForm: "ResERSEPT790/16:Inv.Oblig.Tpte",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept790/16:inv.oblig. tpte",
      nombreForm: "ResERSEPT790/16:Inv.Oblig.Tpte",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "neto de redondeo",
      nombreForm: "Ajustes y otros conceptos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept645/20:inv.oblig.dist",
      nombreForm: "ResERSEPT645/20:Inv.Oblig.Dist",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percepción",
      nombreForm: "Percepción RG 3337",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "registro reactivo kvahr",
      nombreForm: "Energía Reactiva",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo por demanda",
      nombreForm: "Cargo por Demanda",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía",
      nombreForm: "Energía Consumida",
      consump: false,
      cost: true,
    },
  ],
  "EDET - T2": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Exceso de potencia",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "aporte tis-resol. n°463/me",
      nombreForm: "Aporte TIS-Resol. N°463/ME",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "imp. nac. ley 25.413",
      nombreForm: "Imp. Nac. Ley 25.413",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "registros fuera de pico (05 a 18 hs) kwh",
      nombreForm: "Energía consumida Fuera de Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "registros en pico (18 a 23 hs) kwh",
      nombreForm: "Energía consumida en Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "registros nocturnos (23 a 05 hs) kwh",
      nombreForm: "Energía consumida Nocturno",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tic-art. 63 ley 6608",
      nombreForm: "TIC-Art. 63 Ley 6608 actualiz",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tic-art. 63 ley 6608 actualiz",
      nombreForm: "TIC-Art. 63 Ley 6608 actualiz",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva resp. inscr. 27%",
      nombreForm: "IVA Resp. Inscr. 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "bonif. fact. potencia",
      nombreForm: "Energía Reactiva",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept790/16:inv.oblig.tpte",
      nombreForm: "ResERSEPT790/16:Inv.Oblig.Tpte",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept790/16:inv.oblig. tpte",
      nombreForm: "ResERSEPT790/16:Inv.Oblig.Tpte",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "neto de redondeo",
      nombreForm: "Ajustes y otros conceptos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resersept645/20:inv.oblig.dist",
      nombreForm: "ResERSEPT645/20:Inv.Oblig.Dist",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percepción",
      nombreForm: "Percepción RG 3337",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "registro reactivo kvahr",
      nombreForm: "Energía Reactiva",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo por demanda",
      nombreForm: "Cargo por Demanda",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía",
      nombreForm: "Energía Consumida",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "exceso de potencia",
      nombreForm: "Exceso de potencia",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "pico",
      nombreForm: "Exceso de potencia",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ord.mun.926/98",
      nombreForm: "Ord.Mun.926/98",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ord.mun.2141/18",
      nombreForm: "Ord.Mun.2141/18",
      consump: false,
      cost: true,
    },
  ],
  "ENERSA Tarifa 1 - Uso General": [
    {
      nombreFactura: "cargo fijo bimestral",
      nombreForm: "Cargo Fijo Bimestral",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal cf/cv 15,00%",
      nombreForm: "Tasa municipal CF/CV 15,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "consumo energía activa p/bloques",
      nombreForm: "Consumo Energia Activa p/ Bloques",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 8,6956%",
      nombreForm: "Contribución Municipal 8,6956%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva resp. ins. exento de percepción 27,00%",
      nombreForm: "IVA Resp. Ins. Execto de Perc. 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Convenida",
      consump: true,
      cost: false,
    },
  ],
  "ENERSA Tarifa 2 - Medianas Demandas": [
    {
      nombreFactura: "transgresión de potencia 50,00%",
      nombreForm: "Transgresión de Potencia",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal cv 12,00%",
      nombreForm: "Tasa Municipal CV 12,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva resp. ins. exento de percepción 27,00%",
      nombreForm: "IVA Resp. Ins. Exento de Perc. 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa percepción ater 3,00%",
      nombreForm: "Tasa Percepción ATER 3,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contribución municipal 8,6956%",
      nombreForm: "Contribución Municipal 8,6956%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "c.f. men. p/pot.",
      nombreForm: "Cargo Fijo",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "consumo energía activa p/bloques",
      nombreForm: "Energía activa T2",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Convenida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia Registrada",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Transgresión de Potencia",
      consump: true,
      cost: false,
    },
  ],
  "EDEN T2 B1": [
    {
      nombreFactura: "energia act. fuera pico",
      nombreForm: "Energía Activa fuera de pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energla act. fuera pico",
      nombreForm: "Energía Activa fuera de pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "exceso de potencia fuera de pico",
      nombreForm: "Exceso de Potencia Fuera de Pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "exceso de energla reactiva",
      nombreForm: "Energía reactiva",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "potencia fuera de pico",
      nombreForm: "Potencia fuera de Pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energla activa pico",
      nombreForm: "Energía Activa pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia contratada pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Potencia en Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "exceso de potencia en pico",
      nombreForm: "Exceso de Potencia Pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ict- art. 5 res. misp 186/19",
      nombreForm: "ICT - Art 5 Rest. MISP 186/19",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "potencia pico",
      nombreForm: "Potencia en Pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ley pcial. 11969 art. 72 ter 6.00000%",
      nombreForm: "Ley Pcial. 11969 art. 72 ter 6,0000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa de alumbrado publico",
      nombreForm: "Tasa de Alumbrado Publico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley pcial. 11769 res. 665",
      nombreForm: "Ley Pcial. 11769 RES. 665/ 5,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley pcial. 7290 1.00000%",
      nombreForm: "Ley Pcial. 7290 a 1.00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley pcial. 11969 art. 72 bis 0.00100%",
      nombreForm: "Ley Pcial. 11969 art. 72 Bis 0,00100%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa seguridad",
      nombreForm: "Tasa Seguridad",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva responsable inscripto 27.00000%",
      nombreForm: "I.V.A Resp. Inscripto 27,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "fondo a.educ.",
      nombreForm: "Fondo A. Educ. $140 y T. Pub. Pasajeros $110",
      consump: false,
      cost: true,
    },
  ],
  "CALF T2MD Baja Tensión": [
    {
      nombreFactura: "tasa audit. inspecc. y control",
      nombreForm: "Tasa audit. Inspecc. Y Control",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "derecho uso espacio publico",
      nombreForm: "Derecho uso espacio público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "intereses y recargos 1/1",
      nombreForm: "Intereses y recargos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal alumbr.publico",
      nombreForm: "Tasa municipal alumbr. público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "aviso de corte 1/1",
      nombreForm: "Aviso de corte",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tangente",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "resol.035/19 ingresos brutos",
      nombreForm: "Resol. 035/19 Ingresos Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "potencia maxima",
      nombreForm: "Demanda Contratada Máxima",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Demanda Contratada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Demanda Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Demanda Excedida Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "iva percepcion r.g.3337 afip",
      nombreForm: "IVA Percepción R.G.3337 AFIP",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "aporte de capitalizacion",
      nombreForm: "Aporte de capitalización",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva",
      nombreForm: "IVA",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "consumo",
      nombreForm: "Energía activa",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía activa",
      nombreForm: "Energía activa",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "activa",
      nombreForm: "Energía activa",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "reactiva",
      nombreForm: "Energía reactiva",
      consump: true,
      cost: false,
    },
  ],
  "CALF T3GDBT 1 Baja Tensión": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Demanda Contratada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Demanda Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Demanda Excedida Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tangente",
      nombreForm: "Tg fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "tasa audit. inspecc. y control",
      nombreForm: "Tasa audit. Inspecc. Y Control",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "intereses y recargos 1/1",
      nombreForm: "Intereses y recargos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa municipal alumbr.publico",
      nombreForm: "Tasa municipal alumbr. público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "potencia horas pico",
      nombreForm: "Demanda Registrada Pico",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "aporte de capitalizacion",
      nombreForm: "Aporte de capitalización",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resol.035/19 ingresos brutos",
      nombreForm: "Resol. 035/19 Ingresos Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "exceso de potencia",
      nombreForm: "Demanda Excedida Máxima",
      consump: false,
      cost: true,
    },
    { nombreFactura: "iva", nombreForm: "IVA", consump: false, cost: true },
    {
      nombreFactura: "potencia maxima",
      nombreForm: "Demanda Registrada Máxima",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "derecho uso espacio publico",
      nombreForm: "Derecho uso espacio público",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía activa",
      nombreForm: "Energía activa",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "consumo",
      nombreForm: "Energía activa",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "reactiva",
      nombreForm: "Energía reactiva",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "reactiva",
      nombreForm: "Tasa audit. Inspecc. Y Control",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva percepcion r.g.3337 afip",
      nombreForm: "IVA Percepción R.G.3337 AFIP",
      consump: false,
      cost: true,
    },
  ],
  "EPE_Santa_Fe T2  2 B1": [
    {
      nombreFactura: "coseno",
      nombreForm: "Tangente fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "i.v.a.",
      nombreForm: "I.V.A (s/básico + CAP) 27%",
      consump: false,
      cost: true,
    },
    // {
    //   nombreFactura: "i.v.a. (s/bbsico + cap) 27,00%",
    //   nombreForm: "I.V.A (s/básico + CAP) 27%",
    //   consump: false,
    //   cost: true,
    // },
    {
      nombreFactura: "cargo por potencia adquirida",
      nombreForm: "Capacidad de suministro registrada/adquirida T3",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "Capacidad de suministro registrada/adquirida T3",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo por capacidad de suministro",
      nombreForm: "Capacidad de suministro contratada/convenida T3",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Capacidad de suministro contratada/convenida T3",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ord. mun.1",
      nombreForm: "Ord. Mun. 1 (s/Básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ord. mun.2",
      nombreForm: "Ord. Mun. 2 (s/Básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley nro. 23681",
      nombreForm: "Ley Nro. 23681 (s/básico) 0,00%",
      consump: false,
      cost: true,
    },
    // {
    //   nombreFactura: "ley nro. 23681 (s/bbsico) 0,00%",
    //   nombreForm: "Ley Nro. 23681 (s/básico) 0,00%",
    //   consump: false,
    //   cost: true,
    // },Ley N€ 12692 EnergÝas Renovables
    {
      nombreFactura: "ley n° 6604-fer",
      nombreForm: "Ley N° 6604-FER (s/Básico) 1,50%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n€ 6604-fer",
      nombreForm: "Ley N° 6604-FER (s/Básico) 1,50%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n° 12692 energías renovables",
      nombreForm: "Ley N° 12692 Energías Renovables",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n€ 12692",
      nombreForm: "Ley N° 12692 Energías Renovables",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cuota alumb.pco.(cap)",
      nombreForm: "Cuota de Alumbrado Público (CAP)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "rg afip nr. 3337 (s/bas. + cap) 3,00%",
      nombreForm: "RG AFIP Nr. 3337 (s/Bas. + CAP) 3,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley nro. 7797",
      nombreForm: "Ley Nro. 7797 (s/básico) 6,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía reactiva consumida",
      nombreForm: "Energia Reactiva T3",
      consump: true,
      cost: false,
    },
    // {
    //   nombreFactura: "horario resto\n$/kwh",
    //   nombreForm: "Energ. Horario Resto",
    //   consump: true,
    //   cost: true,
    // },
    {
      nombreFactura: "horario resto",
      nombreForm: "Energ. Horario Resto",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo comercial",
      nombreForm: "Cargo Comercial T3",
      consump: false,
      cost: true,
    },
    // {
    //   nombreFactura: "horario valle\n$/kwh",
    //   nombreForm: "Energ. Horario Valle",
    //   consump: true,
    //   cost: true,
    // },
    {
      nombreFactura: "horario valle",
      nombreForm: "Energ. Horario Valle",
      consump: true,
      cost: true,
    },
    // {
    //   nombreFactura: "horario pico\n$/kwh",
    //   nombreForm: "Energ. Horario Pico",
    //   consump: true,
    //   cost: true,
    // },
    {
      nombreFactura: "horario pico",
      nombreForm: "Energ. Horario Pico",
      consump: true,
      cost: true,
    },
  ],
  "EPE_Santa_Fe T2 2A11": [
    {
      nombreFactura: "ord. mun. n.° 1618/62 (1,80% del básico)",
      nombreForm: "Ord. Mun. N° 1592/62 (1,80% del Básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ord. mun. n.° 1592/62 (0,60% del básico)",
      nombreForm: "Ord. Mun. N° 1592/62 (0,60% del Básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n.° 7797 (6,00% del básico)",
      nombreForm: "Ley Nro. 7797 (s/básico) 6,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n.ỗ 7797 (6,00% del básico)",
      nombreForm: "Ley Nro. 7797 (s/básico) 6,00%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "i.v.a. res. general (afip)2408",
      nombreForm: "I.V.A Res. General (AFIP)2408",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cuota de alumbrado público (c.a.p.)",
      nombreForm: "Cuota de Alumbrado Público (CAP)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n°6604-fer (1,50% del básico)",
      nombreForm: "Ley Nro. 6604-FER (1,5% del básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley nõ6604-fer (1,50%",
      nombreForm: "Ley Nro. 6604-FER (1,5% del básico)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cuota de servicio",
      nombreForm: "Cuota de Servicio T2",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley n°12692 energías renovables",
      nombreForm: "Ley N° 12692 Energías Renovables",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley nõ12692 energías renovables",
      nombreForm: "Ley N° 12692 Energías Renovables",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "primeros",
      nombreForm: "Primeros Kwh",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "segundos",
      nombreForm: "Segundos Kwh",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "terceros",
      nombreForm: "Terceros Kwh",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "ultimos",
      nombreForm: "Últimos Kwh",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "(27,00% sobre básico más c.a.p)",
      nombreForm: "RESPONSABLE INSCRIPTO (27,00% sobre básico más C.A.P)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "responsable inscripto (27,00% sobre básico más c.a.p)",
      nombreForm: "RESPONSABLE INSCRIPTO (27,00% sobre básico más C.A.P)",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "responsable inscripto",
      nombreForm: "RESPONSABLE INSCRIPTO (27,00% sobre básico más C.A.P)",
      consump: false,
      cost: true,
    },
  ],
  "EPEC TARIFA 3-GDES. CO": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Demanda Pico (DP) autorizada",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno Fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ajuste coseno fi",
      nombreForm: "Coseno Fi",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "demanda en pico",
      nombreForm: "Demanda Pico (DP) autorizada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "ord.imp. municipal",
      nombreForm: "Ord.imp. municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "fdo.des.ene.prov",
      nombreForm: "Fdo.Des.Ene.Prov",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva inscripto",
      nombreForm: "IVA Inscripto",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ersep-ley 10281 r.27",
      nombreForm: "ERSeP-Ley 10281 R.27",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "dto. 2298",
      nombreForm: "Dto. 2298",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía resto",
      nombreForm: "Energía Resto (EAR)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energía pico",
      nombreForm: "Energía Pico (EAP)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "dr",
      nombreForm: "Demanda Resto (DR) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "dv",
      nombreForm: "Demanda Valle (DV) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ert",
      nombreForm: "Energía Reactiva (ERT)",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "dp",
      nombreForm: "Demanda Pico (DP) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "energía valle",
      nombreForm: "Energía Valle (EAV)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "*)percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "(*)percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "demanda fuera pico",
      nombreForm: "Demanda Fuera Pico (DF) autorizada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "demanda adquirida",
      nombreForm: "Demanda Adquirida",
      consump: false,
      cost: true,
    },
  ],
  "EPEC CONT.EN BAJA": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Demanda Pico (DP) autorizada",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno Fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ajuste coseno fi",
      nombreForm: "Coseno Fi",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "demanda en pico",
      nombreForm: "Demanda Pico (DP) autorizada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "ord.imp. municipal",
      nombreForm: "Ord.imp. municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "fdo.des.ene.prov",
      nombreForm: "Fdo.Des.Ene.Prov",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva inscripto",
      nombreForm: "IVA Inscripto",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ersep-ley 10281 r.27",
      nombreForm: "ERSeP-Ley 10281 R.27",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "dto. 2298",
      nombreForm: "Dto. 2298",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "energía resto",
      nombreForm: "Energía Resto (EAR)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "energía pico",
      nombreForm: "Energía Pico (EAP)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "dr",
      nombreForm: "Demanda Resto (DR) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "dv",
      nombreForm: "Demanda Valle (DV) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ert",
      nombreForm: "Energía Reactiva (ERT)",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "dp",
      nombreForm: "Demanda Pico (DP) consumida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "energía valle",
      nombreForm: "Energía Valle (EAV)",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "*)percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "(*)percep.ing.brutos",
      nombreForm: "Percep.Ing.Brutos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "demanda fuera pico",
      nombreForm: "Demanda Fuera Pico (DF) autorizada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "demanda adquirida",
      nombreForm: "Demanda Adquirida",
      consump: false,
      cost: true,
    },
  ],
  "EDELAP T2B11 BT": [
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Demanda Contratada",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ley prov 11769 - art 43 res 419/17 mlysp 5.00000%",
      nombreForm: "Ley Prov 11769-Art 43 Res 419/17 MIySP 5,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley 11.769 art. 74 0.00100%",
      nombreForm: "Ley 11.769 Art. 74 0,00100%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resolución gral. 3337/91 dgi 3.00000%",
      nombreForm: "Resolución Gral. 3337/91 DGI 3,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ley 11.769 art. 75 6.00000%",
      nombreForm: "Ley 11.769 Art. 75 6,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "iva responsable inscripto - ley nac 23.349 27.00000%",
      nombreForm: "IVA Resp. Insc.-Ley Nac 23.349 27,00000%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa de iluminación",
      nombreForm: "Tasa de Iluminación Municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa de iluminacion",
      nombreForm: "Tasa de Iluminación Municipal",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ict art. 5 mlysp res 186/19",
      nombreForm: "ICT Art. 5 MlySP Res 186/9",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "demanda contratada",
      nombreForm: "Demanda Contratada",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Energía activa/ Cargo variable",
      consump: true,
      cost: true,
    },
    {
      nombreFactura: "gastos administrativos",
      nombreForm: "Gastos Administrativos",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "ajuste de interés por mora",
      nombreForm: "Ajuste de interés por Mora",
      consump: false,
      cost: true,
    },
  ],
  "EDEMSA T2 Especial": [
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Potencia Excedida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "i.v.a. resp. inscripto",
      nombreForm: "I.V.A Resp. Inscripto 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "pico 18 a 23hs",
      nombreForm: "Potencia Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ajuste redondeo factura anterior",
      nombreForm: "Intereses por pago fuera de término",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "subsidio estado nacional",
      nombreForm: "Subsidio Estado Nacional",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "reactiva",
      nombreForm: "Energía reactiva",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cap mun. sist. sol. -ley 9.219 art. 22 y res. ssp 91/20",
      nombreForm: "Cargo AP Municipal - Ordenanza 3938",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo ap municipal",
      nombreForm: "Cargo AP Municipal - Ordenanza 3938",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Cargo Variable",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo comercialización",
      nombreForm: "Cargo Comercialización",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "impuestos provinciales leyes 6922/8398 iibb",
      nombreForm: "Impuestos Provinciales Leyes 6922/8398 IIBB",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resto 5 a 18hs",
      nombreForm: "Potencia Registrada Resto",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ccce art",
      nombreForm: "CCCE Ley6497; Dto1742/16: Res MEyM 6/16",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa fisc.y control art 64 ley 6497",
      nombreForm: "Tasa Fisc. Y Control Ley6497: Res MEyM 6/16",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "valle 23 a 5hs",
      nombreForm: "Potencia Registrada Valle",
      consump: true,
      cost: false,
    },

  ],
  "EDEMSA T2 R BT": [
    {
      nombreFactura: "adquirida-demandada",
      nombreForm: "",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "contratada-convenida",
      nombreForm: "Potencia Contratada/ Uso de Red",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "uso de red",
      nombreForm: "Potencia Contratada/ Uso de Red",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "coseno",
      nombreForm: "Coseno fi",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "excedida",
      nombreForm: "Potencia Excedida",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "i.v.a. resp. inscripto",
      nombreForm: "I.V.A Resp. Inscripto 27%",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "pico 18 a 23hs",
      nombreForm: "Potencia Registrada Pico",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ajuste redondeo factura anterior",
      nombreForm: "Intereses por pago fuera de término",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "subsidio estado nacional",
      nombreForm: "Subsidio Estado Nacional",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "reactiva",
      nombreForm: "Energía reactiva",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "cargo fijo",
      nombreForm: "Cargo Fijo",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cap mun. sist. sol. -ley 9.219 art. 22 y res. ssp 91/20",
      nombreForm: "Cargo AP Municipal - Ordenanza 3938",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo ap municipal",
      nombreForm: "Cargo AP Municipal - Ordenanza 3938",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo variable",
      nombreForm: "Cargo Variable",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "cargo comercialización",
      nombreForm: "Cargo Comercialización",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "impuestos provinciales leyes 6922/8398 iibb",
      nombreForm: "Impuestos Provinciales Leyes 6922/8398 IIBB",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "resto 5 a 18hs",
      nombreForm: "Potencia Registrada Resto",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "ccce art",
      nombreForm: "CCCE Ley6497 ; Dto1742/16 ; Res. MEyM 6/16 ",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "tasa fisc.y control art 64 ley 6497",
      nombreForm: "Tasa Fisc. Y Control Ley6497: Res MEyM 6/16",
      consump: false,
      cost: true,
    },
    {
      nombreFactura: "valle 23 a 5hs",
      nombreForm: "Potencia Registrada Valle",
      consump: true,
      cost: false,
    },
    {
      nombreFactura: "imp.sellos-art240 cod fiscal-ley8523 nuevo contrato",
      nombreForm: "Imp.sellos-Art240 Cod Fiscal-Ley8523 nuevo contrato",
      consump: true,
      cost: false,
    },
  ],
};
