import XLSX from "sheetjs-style"; // Asegúrate de importar sheetjs-style
import { useDispatch, useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";
import { Toast } from "../../../helpers/alert";
import { getEnergyReport } from "../../../redux/apiCalls/billsReportCall";

export default function AuditEnergyReport({ filters }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);
  const supplierSelected =
    filters?.supplierSelected !== "allSuppliers"
      ? filters?.supplierSelected?.toLowerCase()
      : "";

  const downloadExcel = () => {
    Toast.fire({
      icon: "info",
      title: "Se está generando el reporte. Pronto comenzará la descarga.",
    });

    getEnergyReport(dispatch, user?.accessToken, filters)
      .then((res) => {
        const wb = XLSX.utils.book_new();
        const monthsName = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        const headerDates = [
          `AUDITORÍA DE ENERGÍA - ${supplierSelected} ${filters?.year}`,
        ];

        const aggregatedData = {};

          const monthMapping = {
            ene: 0,
            feb: 1,
            mar: 2,
            abr: 3,
            may: 4,
            jun: 5,
            jul: 6,
            ago: 7,
            sep: 8,
            oct: 9,
            nov: 10,
            dic: 11,
          };
          
          Object.keys(res).forEach((month) => {
            const [monthName] = month.split(" ");
            const normalizedMonth = monthName.substring(0, 3).toLowerCase();
            const monthIndex = monthMapping[normalizedMonth];
          
            if (monthIndex === undefined) {
              console.warn(`Mes no reconocido: ${monthName}`);
              return; // Salta meses no reconocidos.
            }
          
            const monthData = res[month];
            if (Array.isArray(monthData)) {
              monthData.forEach((item) => {
                const site = item["nombre del sitio"];
                if (!aggregatedData[site]) {
                  aggregatedData[site] = {
                    facturasConsideradas: new Array(12).fill("N/A"),
                    otrosDatos: {},
                    proveedor: item["proveedor"] || "N/A",
                  };
                }
          
                aggregatedData[site].facturasConsideradas[monthIndex] =
                  item["act"] ?? "N/A";
          
                const classConcepts = {};
          
                Object.keys(item).forEach((key) => {
                  if (
                    !["nombre del sitio", "proveedor", "mes", "año", "act"].includes(key)
                  ) {
                    const [concept, cls] = key.split(" - ");
                    if (!classConcepts[cls]) classConcepts[cls] = new Set();
                    classConcepts[cls].add(concept);
                  }
                });
          
                Object.keys(classConcepts).forEach((cls) => {
                  const conceptArray = [...classConcepts[cls]];
                  if (conceptArray.length === 1) {
                    const key = cls;
                    if (!aggregatedData[site].otrosDatos[key]) {
                      aggregatedData[site].otrosDatos[key] = new Array(12).fill("N/A");
                    }
                    aggregatedData[site].otrosDatos[key][monthIndex] =
                      item[`${conceptArray[0]} - ${cls}`] ?? "N/A";
                  } else {
                    conceptArray.forEach((concept) => {
                      const key = `${concept} - ${cls}`;
                      if (!aggregatedData[site].otrosDatos[key]) {
                        aggregatedData[site].otrosDatos[key] = new Array(12).fill("N/A");
                      }
                      aggregatedData[site].otrosDatos[key][monthIndex] =
                        item[key] ?? "N/A";
                    });
                  }
                });
              });
            }
          });
          
        const ws = XLSX.utils.aoa_to_sheet([
          ["", "", ""].concat(headerDates),
          [],
          ["SUCURSALES","PROVEEDOR", "CLASES"].concat(monthsName),
        ]);

        ws["!cols"] = [
          { width: 30 },
          { width: 30 },
          { width: 30 },
          ...Array(monthsName.length).fill({ width: 12 }),
        ];

        let rowIndex = 4;

        Object.keys(aggregatedData).forEach((site) => {
          const data = aggregatedData[site];
          const classOrder = [
            "Energía Consumida",
            "Energía Renovable",
            "Energía Autogenerada"
          ];

       // Ordenar las claves de 'otrosDatos' usando tu lógica actual
       const sortedKeys = Object.keys(data.otrosDatos).sort((a, b) => {
        const getClass = (key) => {
          const parts = key.split(" - ");
          return parts.length === 2 ? parts[1]?.trim() : parts[0]?.trim();
        };
    
        const classA = getClass(a);
        const classB = getClass(b);
    
        const indexA = classOrder.findIndex((order) => classA.includes(order));
        const indexB = classOrder.findIndex((order) => classB.includes(order));
    
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
    
        return indexA - indexB;
      });
    
      // Agregar el nombre del sitio, proveedor y las facturas consideradas
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          // En esta línea agregamos el proveedor en la segunda columna
          [site, data.proveedor, "Facturas consideradas"].concat(data.facturasConsideradas),
          // Luego, añadimos cada fila para los otros datos (las clases y sus valores)
          ...sortedKeys.map((field) => [site, data.proveedor, field].concat(data.otrosDatos[field])),
        ],
        { origin: { r: rowIndex, c: 0 } }
      );

          sortedKeys.forEach((field, fieldIndex) => {
            const values = data.otrosDatos[field];

            values.forEach((value, valueIndex) => {
              if (valueIndex > 0 && valueIndex < values.length - 1) {
                const prevValue = values[valueIndex - 1];
                const nextValue = values[valueIndex + 1];
                const facturasConsideradasActual = data.facturasConsideradas[valueIndex];
                const facturasConsideradasAnterior = data.facturasConsideradas[valueIndex - 1];
                let prevDiff = Math.abs((value - prevValue) / prevValue);
                const prevDiffPercentage = Math.abs((value - prevValue) / prevValue) * 100;
                const nextDiff = Math.abs((value - nextValue) / nextValue);
                const prevDiff100 = Math.abs((prevValue - value) / value) * 100;
                const nextDiff100 = Math.abs((nextValue - value) / value) * 100;
                
                  // Nueva condición: si facturas consideradas son N/A para el mes actual o el anterior, no colorear
                  if (facturasConsideradasActual === "N/A" || facturasConsideradasAnterior === "N/A") {
                    return;
                  }

                   // Verificar si facturasConsideradasAnterior es 2 y facturasConsideradasActual es 1
                  if (facturasConsideradasAnterior === 2 && facturasConsideradasActual === 1) {
                    // Dividir prevDiff por 2
                    prevDiff = prevDiff / 2;
                  }

                if (
                  (prevDiff >= 0.5 && nextDiff >= 0.5) ||
                  (prevValue && nextValue && value === "N/A") || 
                  (prevDiff100 >= 100 && facturasConsideradasAnterior !== 2)
                ) {
                  const columnIndex = 2 + valueIndex;
                  const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);

                  const cellRef = XLSX.utils.encode_cell({
                    r: baseRow,
                    c: columnIndex,
                  });

                  if (!ws[cellRef]) ws[cellRef] = {};
                  if (!ws[cellRef].s) {
                    ws[cellRef].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: {
                        name: "Arial",
                        color: { rgb: "FFFFFF" },
                      },
                    };
                  }
                }
              }
            });
          });

          rowIndex += 1 + sortedKeys.length;
        });

        XLSX.utils.book_append_sheet(wb, ws, `Reporte ${supplierSelected}`);

        XLSX.writeFile(
          wb,
          `ReporteAuditoriaEnergia_Año_${filters?.year}_Proveedor_${supplierSelected}.xlsx`
        );
      })
      .catch((err) => {
        Toast.fire({
          icon: "error",
          title: `Hubo un error al intentar descargar el reporte. Intentelo nuevamente o pruebe otras opciones. ${err}`,
        });
      });
  };

  return (
    <>
      <button onClick={downloadExcel}>
        <FaFileDownload /> Descargar energía por mes
      </button>
    </>
  );
}
