import XLSX from "sheetjs-style";
import { useDispatch, useSelector } from "react-redux";
import { FaFileDownload } from "react-icons/fa";
import { Toast } from "../../../helpers/alert";
import { getPowerReport } from "../../../redux/apiCalls/billsReportCall";

export default function AuditPowerReport({ filters }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user?.currentUser);
  const supplierSelected =
    filters?.supplierSelected !== "allSuppliers"
      ? filters?.supplierSelected
      : "";

  const downloadExcel = () => {
    Toast.fire({
      icon: "info",
      title: "Se está generando el reporte. Pronto comenzará la descarga.",
    });

    getPowerReport(dispatch, user?.accessToken, filters)
      .then((res) => {
        const wb = XLSX.utils.book_new();
        const monthsName = [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ];

        const headerDates = [
          `AUDITORÍA DE POTENCIA - ${supplierSelected} ${filters?.year}`,
        ];

         const classOrder = [
          "Potencia Contratada",
          "Potencia Demandada",
          "Potencia Excedida",
          "Coseno fi",
          "Tangente fi",
          "Total factura"
        ];
        const aggregatedData = {};

        const monthMapping = {
          ene: 0,
          feb: 1,
          mar: 2,
          abr: 3,
          may: 4,
          jun: 5,
          jul: 6,
          ago: 7,
          sep: 8, // Actualización: usa "sep" en lugar de "sept" para mapear correctamente.
          oct: 9,
          nov: 10,
          dic: 11,
        };
        
        Object.keys(res).forEach((month) => {
          const monthData = res[month];
          if (Array.isArray(monthData)) {
            monthData.forEach((item) => {
              const site = item["nombre del sitio"];
        
              if (!aggregatedData[site]) {
                aggregatedData[site] = {
                  facturasConsideradas: new Array(12).fill("N/A"),
                  otrosDatos: {},
                  proveedor: item["proveedor"] || "N/A",
                };
              }
        
              // Extraer el índice del mes.
              const [monthName] = month.split(" "); // Obtén "sept" de "sept 2024".
              const normalizedMonth = monthName.substring(0, 3); // Normaliza a "sep".
              const monthIndex = monthMapping[normalizedMonth];
        
              if (monthIndex === undefined) {
                console.warn(`Mes no reconocido: ${monthName}`);
                return; // Evitar procesar meses no reconocidos.
              }
        
              aggregatedData[site].facturasConsideradas[monthIndex] =
                item["act"] ?? "N/A";
        
              const classConcepts = {};
        
              Object.keys(item).forEach((key) => {
                if (
                  ![
                    "nombre del sitio",
                    "proveedor",
                    "mes",
                    "año",
                    "act",
                  ].includes(key)
                ) {
                  const [concept, cls] = key.split(" - ");
        
                  if (!classConcepts[cls]) {
                    classConcepts[cls] = new Set();
                  }
                  classConcepts[cls].add(concept);
                }
              });
        
              Object.keys(classConcepts).forEach((cls) => {
                if (classConcepts[cls].size === 1) {
                  const concept = [...classConcepts[cls]][0];
                  const key = `${cls}`;
        
                  if (!aggregatedData[site].otrosDatos[key]) {
                    aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                      "N/A"
                    );
                  }
                  aggregatedData[site].otrosDatos[key][monthIndex] =
                    item[`${concept} - ${cls}`] ?? "N/A";
                } else {
                  classConcepts[cls].forEach((concept) => {
                    const key = `${concept} - ${cls}`;
        
                    if (!aggregatedData[site].otrosDatos[key]) {
                      aggregatedData[site].otrosDatos[key] = new Array(12).fill(
                        "N/A"
                      );
                    }
                    aggregatedData[site].otrosDatos[key][monthIndex] =
                      item[key] ?? "N/A";
                  });
                }
              });
            });
          }
        });
        
         // Validar si en otrosDatos solo existe "Total factura", si es así, eliminar el sitio
    Object.keys(aggregatedData).forEach((site) => {
      const otrosDatosKeys = Object.keys(aggregatedData[site].otrosDatos);
      if (otrosDatosKeys.length === 1 && otrosDatosKeys[0] === "Total factura") {
        delete aggregatedData[site];  // Eliminar el sitio si solo tiene "Total factura"
      }
    });
        // Obtener el mes actual (enero es 0, diciembre es 11)
        const currentMonthIndex = new Date().getMonth();

        // Reemplazamos los "N/A" por 0, excepto para "Total factura", "Facturas consideradas", "Coseno fi" y "Tangente fi"
        // y solo hasta el mes actual
        Object.keys(aggregatedData).forEach((site) => {
          Object.keys(aggregatedData[site].otrosDatos).forEach((key) => {
            if (!["Total factura", "Coseno fi", "Tangente fi"].includes(key)) {
              aggregatedData[site].otrosDatos[key] = aggregatedData[site].otrosDatos[key].map((value, index) =>
                // Aplicamos el reemplazo de "N/A" solo si el índice (mes) es menor o igual al mes actual
                index <= currentMonthIndex && value === "N/A" ? 0 : value
              );
            }
          });
        });

        const ws = XLSX.utils.aoa_to_sheet([
          ["", "", ""].concat(headerDates),
          [],
          ["SUCURSALES","PROVEEDOR", "CLASES"].concat(monthsName),
        ]);

        ws["!cols"] = [
          { width: 30 },
          { width: 30 },
          { width: 30 },
          ...Array(monthsName.length).fill({ width: 12 }),
        ];

        let rowIndex = 4;

        Object.keys(aggregatedData).forEach((site) => {
          const data = aggregatedData[site];
        
          // Ordenar las claves de 'otrosDatos' usando tu lógica actual
          const sortedKeys = Object.keys(data.otrosDatos).sort((a, b) => {
            const getClass = (key) => {
              const parts = key.split(" - ");
              return parts.length === 2 ? parts[1]?.trim() : parts[0]?.trim();
            };
        
            const classA = getClass(a);
            const classB = getClass(b);
        
            const indexA = classOrder.findIndex((order) => classA.includes(order));
            const indexB = classOrder.findIndex((order) => classB.includes(order));
        
            if (indexA === -1 && indexB === -1) return 0;
            if (indexA === -1) return 1;
            if (indexB === -1) return -1;
        
            return indexA - indexB;
          });
        
          // Agregar el nombre del sitio, proveedor y las facturas consideradas
          XLSX.utils.sheet_add_aoa(
            ws,
            [
              // En esta línea agregamos el proveedor en la segunda columna
              [site, data.proveedor, "Facturas consideradas"].concat(data.facturasConsideradas),
              // Luego, añadimos cada fila para los otros datos (las clases y sus valores)
              ...sortedKeys.map((field) => [site, data.proveedor, field].concat(data.otrosDatos[field])),
            ],
            { origin: { r: rowIndex, c: 0 } }
          );

          function validarFacturasConsideradas(index, facturasConsideradas) {
            const actual = facturasConsideradas[index];
            const anterior = facturasConsideradas[index - 1];
            const siguiente = facturasConsideradas[index + 1];
          
            // Si alguna de las facturas consideradas es "N/A", retornar false para saltar el coloreado
            if (actual === "N/A" || anterior === "N/A" || siguiente === "N/A") {
              return false;
            }
          
            return true;
          }
          

          sortedKeys.forEach((field) => {      
              const values = data.otrosDatos[field];
              // Coloring logic for "Coseno fi"
              if (field.toLowerCase() === "coseno fi") {
                // Loop through each value in "Coseno fi"
                values.forEach((value, valueIndex) => {
                  const columnIndex = 3 + valueIndex;
                  const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                  const cellRef = XLSX.utils.encode_cell({ r: baseRow, c: columnIndex });
              
                  // Usar la función para validar las facturas consideradas
                  if (!validarFacturasConsideradas(valueIndex, data.facturasConsideradas)) {
                    return;
                  }
              
                  // Set the style for cells regardless of whether the value is "N/A" or a valid number
                  if (!ws[cellRef]) ws[cellRef] = {};
              
                  // Apply the coloring based on the value
                  if (value === 'N/A') {
                    ws[cellRef].s = {
                      fill: { fgColor: { rgb: "ff5252" } },  // Red background for "N/A"
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },  // White text
                    };
                  }
                });
              }
              
              
              if (field.toLowerCase() === "tangente fi") {
                  // Loop through each value in "Tangente fi"
                  values.forEach((value, valueIndex) => {
                      const columnIndex = 3 + valueIndex;
                      const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                      const cellRef = XLSX.utils.encode_cell({ r: baseRow, c: columnIndex });
                          // Usar la función para validar las facturas consideradas
                  if (!validarFacturasConsideradas(valueIndex, data.facturasConsideradas)) {
                    return;
                  }
                          // Set the style for cells regardless of whether the value is "N/A" or a valid number
                          if (!ws[cellRef]) ws[cellRef] = {};
          
                          // Apply the coloring based on the value
                          if (value === 'N/A') {
                              ws[cellRef].s = {
                                  fill: { fgColor: { rgb: "ff5252" } },  // Red background for "N/A"
                                  font: { name: "Arial", color: { rgb: "FFFFFF" } },  // White text
                              };
                          }
                      
                  });
              }
         
          // Lógica de coloreado para "Potencia Demandada"
            if (field.toLowerCase().includes("potencia demandada")) {
              const values = data.otrosDatos[field];

              values.forEach((value, valueIndex) => {
                  // Usar la función para validar las facturas consideradas
                  if (!validarFacturasConsideradas(valueIndex, data.facturasConsideradas)) {
                    return;
                  }
                if (valueIndex > 1) {
                  const prevValue = values[valueIndex - 1];
                  const twoMonthsAgoValue = values[valueIndex - 2] || "N/A";
                  const oneMonthAgoValue = values[valueIndex - 1] || "N/A";

                  if (
                    value !== "N/A" &&
                    oneMonthAgoValue !== "N/A" &&
                    twoMonthsAgoValue !== "N/A" &&
                    value === twoMonthsAgoValue * 2
                  ) {
                    const columnIndex = 3 + valueIndex;
                    const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                    const cellRef = XLSX.utils.encode_cell({
                      r: baseRow,
                      c: columnIndex,
                    });

                    if (!ws[cellRef]) ws[cellRef] = {};
                    if (!ws[cellRef].s) {
                      ws[cellRef].s = {
                        fill: { fgColor: { rgb: "ff5252" } },
                        font: {
                          name: "Arial",
                          color: { rgb: "FFFFFF" },
                        },
                      };
                    }
                  }
                }
              });
            }
        // Lógica de coloreado para "Potencia Excedida"
        const epsilon = 0.02;
          if (field.toLowerCase().includes("potencia excedida")) {
            const exceededValues = data.otrosDatos[field];
            const contractedValues = data.otrosDatos["Potencia Contratada"] || [];
            const demandedValues = data.otrosDatos["Potencia Demandada"] || [];

            exceededValues.forEach((exceededValue, valueIndex) => {
              

              const contractedValue = contractedValues[valueIndex];
              const demandedValue = demandedValues[valueIndex];

              if (
                exceededValue !== "N/A" &&
                contractedValue !== "N/A" &&
                demandedValue !== "N/A"
              ) {
                const exceededValueParsed = parseFloat(exceededValue);
                const contractedValueParsed = parseFloat(contractedValue);
                const demandedValueParsed = parseFloat(demandedValue);

                let calculatedExceeded = 0;

                // Lógica especial para EDESUR
                if (
                  supplierSelected.toLowerCase() === "edesur" &&
                  demandedValueParsed >= contractedValueParsed
                ) {
                  calculatedExceeded =
                    (demandedValueParsed - contractedValueParsed) * 1.5010458962;
                } else {
                  calculatedExceeded = demandedValueParsed - contractedValueParsed;
                }

                // Este código resalta la celda solo si la diferencia es significativa y no es 0
                if (Math.abs(calculatedExceeded - exceededValueParsed) > epsilon && exceededValueParsed !== 0) {
                  const columnIndex = 3 + valueIndex;
                  const baseRow = rowIndex + 1 + sortedKeys.indexOf(field);
                  const cellRef = XLSX.utils.encode_cell({
                    r: baseRow,
                    c: columnIndex,
                  });

                  if (!ws[cellRef]) ws[cellRef] = {};
                  if (!ws[cellRef].s) {
                    ws[cellRef].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: {
                        name: "Arial",
                        color: { rgb: "FFFFFF" },
                      },
                    };
                  }
                }
              }
            });
          }
            // Obtener valores de "Demanda Contratada Máxima - Potencia Contratada"
            const demandaContratadaMaximaKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda contratada máxima - potencia contratada")
            );
            const demandaContratadaMaximaValues = data.otrosDatos[demandaContratadaMaximaKey] || [];
        
            // Obtener valores de "Demanda Contratada Pico - Potencia Contratada"
            const demandaContratadaPicoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda contratada pico - potencia contratada")
            );
            const demandaContratadaPicoValues = data.otrosDatos[demandaContratadaPicoKey] || [];
        
            // Comparar los valores
            demandaContratadaMaximaValues.forEach((demandaMaximaValue, index) => {
              const demandaPicoValue = demandaContratadaPicoValues[index];
        
              // Verificar que ninguno de los valores sea "N/A"
              if (demandaMaximaValue !== "N/A" && demandaPicoValue !== "N/A") {
                // Comprobar si los valores son diferentes
                if (parseFloat(demandaMaximaValue) !== parseFloat(demandaPicoValue)) {
                  const columnIndex = 3 + index;
        
                  // Pintar la celda para "Demanda Contratada Máxima - Potencia Contratada"
                  const baseRowMaxima = rowIndex + 1 + sortedKeys.indexOf(demandaContratadaMaximaKey);
                  const cellRefMaxima = XLSX.utils.encode_cell({ r: baseRowMaxima, c: columnIndex });
        
                  if (!ws[cellRefMaxima]) ws[cellRefMaxima] = {};
                  if (!ws[cellRefMaxima].s) {
                    ws[cellRefMaxima].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
        
                  // Pintar la celda para "Demanda Contratada Pico - Potencia Contratada"
                  const baseRowPico = rowIndex + 1 + sortedKeys.indexOf(demandaContratadaPicoKey);
                  const cellRefPico = XLSX.utils.encode_cell({ r: baseRowPico, c: columnIndex });
        
                  if (!ws[cellRefPico]) ws[cellRefPico] = {};
                  if (!ws[cellRefPico].s) {
                    ws[cellRefPico].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
                }
              }
            });
           // Nueva validación para "Demanda Registrada Máxima - Potencia Demandada" vs "Demanda Registrada Pico - Potencia Demandada"}
            const demandaRegistradaMaximaKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda registrada máxima - potencia demandada")
            );
            const demandaRegistradaMaximaValues = data.otrosDatos[demandaRegistradaMaximaKey] || [];

            const demandaRegistradaPicoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda registrada pico - potencia demandada")
            );
            const demandaRegistradaPicoValues = data.otrosDatos[demandaRegistradaPicoKey] || [];

            // Comparar los valores
            demandaRegistradaMaximaValues.forEach((demandaMaximaValue, index) => {
              const demandaPicoValue = demandaRegistradaPicoValues[index];

              // Verificar que ninguno de los valores sea "N/A"
              if (demandaMaximaValue !== "N/A" && demandaPicoValue !== "N/A") {
                // Comprobar si "Demanda Registrada Máxima" es menor o igual a "Demanda Registrada Pico"
                if (parseFloat(demandaMaximaValue) <= parseFloat(demandaPicoValue)) {
                  const columnIndex = 3 + index;

                  // Pintar la celda para "Demanda Registrada Máxima - Potencia Demandada"
                  const baseRowMaximaDemandada = rowIndex + 1 + sortedKeys.indexOf(demandaRegistradaMaximaKey);
                  const cellRefMaximaDemandada = XLSX.utils.encode_cell({ r: baseRowMaximaDemandada, c: columnIndex });

                  if (!ws[cellRefMaximaDemandada]) ws[cellRefMaximaDemandada] = {};
                  if (!ws[cellRefMaximaDemandada].s) {
                    ws[cellRefMaximaDemandada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }

                  // Pintar la celda para "Demanda Registrada Pico - Potencia Demandada"
                  const baseRowPicoDemandada = rowIndex + 1 + sortedKeys.indexOf(demandaRegistradaPicoKey);
                  const cellRefPicoDemandada = XLSX.utils.encode_cell({ r: baseRowPicoDemandada, c: columnIndex });

                  if (!ws[cellRefPicoDemandada]) ws[cellRefPicoDemandada] = {};
                  if (!ws[cellRefPicoDemandada].s) {
                    ws[cellRefPicoDemandada].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
                }
              }
            });

            // Nueva validación para "Demanda Excedida Máxima - Potencia Excedida" vs "Demanda Excedida Pico - Potencia Excedida"
            const demandaExcedidaMaximaKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda excedida máxima - potencia excedida")
            );
            const demandaExcedidaMaximaValues = data.otrosDatos[demandaExcedidaMaximaKey] || [];

            const demandaExcedidaPicoKey = Object.keys(data.otrosDatos).find(key =>
              key.toLowerCase().includes("demanda excedida pico - potencia excedida")
            );
            const demandaExcedidaPicoValues = data.otrosDatos[demandaExcedidaPicoKey] || [];

            // Comparar los valores
            demandaExcedidaMaximaValues.forEach((demandaExcedidaMaximaValue, index) => {
              const demandaExcedidaPicoValue = demandaExcedidaPicoValues[index];

              // Verificar que ninguno de los valores sea "N/A"
              if (demandaExcedidaMaximaValue !== "N/A" && demandaExcedidaPicoValue !== "N/A") {
                // Comprobar si "Demanda Excedida Máxima" es menor o igual a "Demanda Excedida Pico"
                if (parseFloat(demandaExcedidaMaximaValue) <= parseFloat(demandaExcedidaPicoValue)) {
                  const columnIndex = 3 + index;

                  // Pintar la celda para "Demanda Excedida Máxima - Potencia Excedida"
                  const baseRowExcedidaMaxima = rowIndex + 1 + sortedKeys.indexOf(demandaExcedidaMaximaKey);
                  const cellRefExcedidaMaxima = XLSX.utils.encode_cell({ r: baseRowExcedidaMaxima, c: columnIndex });

                  if (!ws[cellRefExcedidaMaxima]) ws[cellRefExcedidaMaxima] = {};
                  if (!ws[cellRefExcedidaMaxima].s) {
                    ws[cellRefExcedidaMaxima].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }

                  // Pintar la celda para "Demanda Excedida Pico - Potencia Excedida"
                  const baseRowExcedidaPico = rowIndex + 1 + sortedKeys.indexOf(demandaExcedidaPicoKey);
                  const cellRefExcedidaPico = XLSX.utils.encode_cell({ r: baseRowExcedidaPico, c: columnIndex });

                  if (!ws[cellRefExcedidaPico]) ws[cellRefExcedidaPico] = {};
                  if (!ws[cellRefExcedidaPico].s) {
                    ws[cellRefExcedidaPico].s = {
                      fill: { fgColor: { rgb: "ff5252" } },
                      font: { name: "Arial", color: { rgb: "FFFFFF" } },
                    };
                  }
                }
              }
            });

            // Validación para encontrar cualquier clave que incluya "Potencia Contratada"
            const potenciaContratadaKey = Object.keys(data.otrosDatos).find(key => key.toLowerCase().includes('potencia contratada'));
            // Si existe una clave que contiene "Potencia Contratada", asigna su valor
            const potenciaContratadaValues = potenciaContratadaKey 
                ? data.otrosDatos[potenciaContratadaKey] 
                : null;

            if (Array.isArray(potenciaContratadaValues)) {
                // Verificamos si todos los valores de "Potencia Contratada" son iguales en todos los meses
                const firstValue = potenciaContratadaValues.find(value => value !== "N/A"); // El primer valor que no sea "N/A"
                // Verificamos si todos los valores son iguales
                const allContratadaEqual = potenciaContratadaValues.every(value => value === firstValue || value === "N/A");

                // Si no todos los valores son iguales, pintamos las celdas con el color de advertencia
                if (!allContratadaEqual) {
                    potenciaContratadaValues.forEach((value, valueIndex) => {
                        // Validar las facturas consideradas antes de aplicar el estilo
                        if (!validarFacturasConsideradas(valueIndex, data.facturasConsideradas)) {
                            return;  // Si la validación falla, saltamos este valor
                        }

                        // Obtén los valores correspondientes de "Facturas consideradas"
                        const facturasConsideradasActual = data.facturasConsideradas[valueIndex];
                        const facturasConsideradasReferencia = data.facturasConsideradas[0]; // El valor de referencia

                        // Si los valores en "Facturas consideradas" justifican la diferencia en "Potencia Contratada", no pintar
                        const esJustificado = facturasConsideradasActual !== "N/A" && 
                                              facturasConsideradasReferencia !== "N/A" && 
                                              value * facturasConsideradasReferencia === firstValue * facturasConsideradasActual;

                        if (!esJustificado && value !== "N/A" && value !== firstValue) { // Pintar solo si el valor es distinto al primero y no justificado
                            const columnIndex = 3 + valueIndex;  // Calculamos el índice de columna basado en el mes
                            const baseRowContratada = rowIndex + 1 + sortedKeys.indexOf("Potencia Contratada");  // Obtenemos la fila para "Potencia Contratada"
                            const cellRefContratada = XLSX.utils.encode_cell({ r: baseRowContratada, c: columnIndex });

                            // Si la celda no tiene estilo, aplicamos el estilo de advertencia
                            if (!ws[cellRefContratada]) ws[cellRefContratada] = {};
                            if (!ws[cellRefContratada].s) {
                                ws[cellRefContratada].s = {
                                    fill: { fgColor: { rgb: "ff5252" } },  // Color de fondo rojo
                                    font: { name: "Arial", color: { rgb: "FFFFFF" } },  // Fuente Arial en blanco
                                };
                            }
                        }
                    });
                }
            }
        });
          rowIndex += 1 + sortedKeys.length;
        });

        XLSX.utils.book_append_sheet(wb, ws, `Potencia ${filters?.year}`);
        XLSX.writeFile(
          wb,
          `AUDITORÍA DE POTENCIA - ${supplierSelected} ${filters?.year}.xlsx`
        );
      })
      .catch((err) => {
        console.error(err);
        Toast.fire({
          icon: "error",
          title: "Error generando el reporte",
        });
      });
  };


  return (
    <>
      <button onClick={downloadExcel}>
        <FaFileDownload /> Descargar potencia por mes
      </button>
    </>
  );
}
