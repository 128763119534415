import "./Topbar.css";
import React from "react";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/logo.png";
import { logoutUser } from "../../redux/apiCalls/loginCall";
import { userSuccess } from "../../redux/reducers/profileReducer";

const Topbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user.currentUser);

  const handleLogout = () => {
    dispatch(userSuccess(null));
    localStorage.removeItem("downloadData");
    localStorage.removeItem("selectedCompanies");
    localStorage.removeItem("selectedDevices");
    localStorage.removeItem("board");
    logoutUser(dispatch, user?.accessToken, { user: user?.user });
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light navbar-app">
      <a
        className="navbar-brand image-logo"
        href="#"
        onClick={() => navigate("/")}
      >
        <img src={logo} alt="Logo bill system" width="200" height="45" />
        <p className="nav-item">ENERGY BILL MANAGER</p>
      </a>
      {user && location.pathname !== "/expired-password" ? (
        <div className="navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link nav-link-custom dropdown-toggle topbar-dropdown"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaBars />
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
                style={{position: "absolute"}}
              >
                {user.privilege === "editor" || user.privilege === "master" ? (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => navigate("/uploadBill")}
                    >
                      Cargar factura
                    </a>
                  </li>
                ) : null}
                {["admin", "master", "editor"].includes(user.privilege) ? (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() =>
                        navigate(
                          user.privilege === "editor"
                            ? "/admin/lists?show=companies"
                            : "/admin/lists?show=users"
                        )
                      }
                    >
                      Administrar
                    </a>
                  </li>
                ) : null}
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => navigate("/admin/audit")}
                  >
                    Auditoría
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => navigate(`profile/${user.id_user}`)}
                  >
                    Tu cuenta
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => navigate(`pass/${user.id_user}`)}
                  >
                    Contraseña
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={handleLogout}
                  >
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      ) : null}
    </nav>
  );
};

export default Topbar;
