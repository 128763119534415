import React from "react";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteCompany } from "../../redux/apiCalls/companiesCall";
import { Toast } from "../../helpers/alert";
import Swal from "sweetalert2";

const Office = ({ company, userLog }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const handleDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      title: `¿Está seguro de borrar la sucursal ${company.name}? No podrá recuperar sus datos.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar sucursal.",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCompany(dispatch, company.id_company, user.accessToken);
          // navigate("/admin/lists?show=companies");
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: "No se pudo borrar la sucursal",
          });
        }
      }
    });
  };

  return (
    <tr className="user-row" id={`company-${company.id_company}`}>
      <td>{company.branch_code}</td>
      <td>{company.name}</td>
      <td>{company.state}</td>
      <td>{company.address}</td>
      <td>{company.m2}</td>
      <td>
        {company.status === "ACTIVA" ? (
          <p className="data-text enable enable-user">ACTIVA</p>
        ) : company.status === "EN OBRA, PAGA ELECTRICIDAD" ? (
          <p className="data-text enable inworks">EN OBRA, PAGA ELECTRICIDAD</p>
        ) : (
          <p className="data-text enable disable-user">BAJA</p>
        )}
      </td>
      <td className="cont-actions">
        {userLog.privilege === "master" ||
        userLog.privilege === "admin" ||
        userLog.privilege === "editor" ? (
          <>
         {userLog.privilege === "master" ||
            userLog.privilege === "editor" ? (
              <div
                className="btnUser-edit btnUser-assign btn-actions"
                idcompany={company.id_company}
                onClick={() =>
                  navigate(`/supplierCompany/${company.id_company}`)
                }
              >
                <FaEdit />
                <p>Asignar proveedor</p>
              </div>
            ) : null}
            <div
              className="btnUser-edit btn-actions"
              onClick={() => navigate(`/branchoffice/${company.id_company}`)}
            >
              <FaEdit />
              <p>Editar</p>
            </div>
          </>
        ) : (
          "No hay acciones permitidas"
        )}
      </td>
    </tr>
  );
};

export default Office;
